import React, { useMemo } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

/**
 * Image wrapper component for cloudinary transformations,...
 * @component
 */
const Image = ({ data, alt = "", style, debug, ...props }) => {
  const gatsbyData = useMemo(() => {
    if (data) {
      try {
        return data.file.childImageSharp.gatsbyImageData;
      } catch (error) {
        if (debug) console.log(error);
      }
    }

    return undefined;
  }, [data]);

  if (debug)
    console.log({
      data,
      gatsbyData,
    });

  if (gatsbyData)
    return <GatsbyImage image={gatsbyData} alt={alt} {...props} />;

  return (
    <img
      src={data?.output ?? ""}
      alt={alt}
      loading="lazy"
      {...props}
    />
  );
};

export default Image;
