export const MENU_LEVEL = {
  BASE_LEVEL: 1, // Menu that appears on the appbar
  SUBMENU_LEVEL: 2, // Menu that appears on the submenu
};

export const MENU_TYPE = {
  ONLY_LINK: "0", // Menu that doesn't show submenu
  SHOW_ARTICLES: "1", // Menu that show submenu with only list of sub-articles
  SHOW_SUBMENU: "2", // Menu that show submenu and list of submenus
};

export const SUBMENU_CONFIG = {
  FADEOUT_TIMEOUT: 500,
  TRANSITION_DURATION: 1000,
  TRANSITION_DIRECTION: "left",
};

export const HEADER_BUTTON_COLORS = {
  UNDERLINE: "Action",
  ICON: "menu_icons",
};

export const HEADER_BUTTON_ICON_SIZE = 35;

export const SUBMENU_COLORS = {
  UNDERLINE: "Action.background",
  LINK_HOVER: "Action.background",
};

export const SUBMENU_CHUNK_SIZE = 5; // For splitting sub-articles in menu type 1
