import React, { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
// utils
import traverse from "@helpers/traverse";
// components
import PromotionDisplay from "../promotion-display/promotion-display";

/**
 * Promotion banner wrapper for managing and passing states
 *
 * @component
 */
const PromotionDisplayWrapper = ({ children, promotionDisplay }) => {
  const data = useStaticQuery(graphql`
    query {
      promotionDisplay {
        _id
      }
    }
  `);

  const hasPromotion = useMemo(() => {
    if (promotionDisplay) return true;

    return Boolean(
      traverse(data, {
        promotionDisplay: undefined,
      }).promotionDisplay
    );
  }, [promotionDisplay, data]);

  return (
    <div className="overflow-x-hidden flex flex-col">
      {hasPromotion ? <PromotionDisplay promotionDisplay={promotionDisplay} /> : null}
      {children}
    </div>
  );
};

export default PromotionDisplayWrapper;
