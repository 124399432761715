import React, { useEffect, useState, useRef, useContext } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
// constants
import { MENU_TYPE } from "@constants/menu";
// hooks
import { useOnClickOutside } from "@hooks/click-outside";
// utils
import traverse from "@helpers/traverse";
import mapColors from "@helpers/map-colors";
// components
import Image from "@components/image/image";
// contexts
import { EventContext } from "@context/event";

const MobileMenu = () => {
  const data = useStaticQuery(graphql`
    query {
      allColor(
        filter: {
          name: { regex: "/Action|color_hamburger|mobile_dropdown|Menu/" }
        }
      ) {
        nodes {
          name
          text
          background
        }
      }
      allMenu(filter: { menuLevel: { eq: 1 } }, sort: { order: ASC }) {
        nodes {
          menuLevel
          order
          subtitle
          title
          iconAltText
          menuType
          _id
          slug
          icon {
            output
          }
          submenus {
            title
            articles {
              menuTitle
              slug
            }
          }
          articles {
            menuTitle
            slug
          }
          categoryArticle {
            slug
          }
        }
      }
      global {
        logoMobile {
          output
          file {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        showLvl1Icon
        showLvl1Subtitle
        logoMainAltText
      }
    }
  `);
  const [open, setOpen] = useState(false); // The anchor element for submenu
  const [menuOpen, setMenuOpen] = useState(-1);
  const [isOnPageTop, setIsOnPageTop] = useState(true);
  const { event } = useContext(EventContext);

  const { allMenu, global, allColor } = traverse(data, {
    allColor: {
      nodes: [
        { name: "Action", background: "#000000", text: "#000000" },
        { name: "color_hamburger", background: "#000000", text: "#000000" },
        { name: "mobile_dropdown", background: "#000000", text: "#000000" },
        { name: "Menu", background: "#000000", text: "#000000" },
      ],
    },
    allMenu: {
      nodes: [],
    },
    global: {
      logoMobile: {
        output: "",
      },
      showLvl1Icon: false,
      showLvl1Subtitle: false,
      logoMainAltText: "",
    },
  });
  const colors = mapColors(allColor);
  const ref = useRef();

  useOnClickOutside(ref, () => setOpen(false), "mobile-menu");

  useEffect(() => {
    setMenuOpen(-1);
  }, [open]);

  useEffect(() => {
    if (event && event.name === "scroll") {
      setIsOnPageTop(window.scrollY < 100);
    }
  }, [event]);

  useEffect(() => {
    if (typeof window !== "undefined") setIsOnPageTop(window.scrollY < 100);
  }, []);

  const onNavigate = () => {
    setMenuOpen(-1);
    setOpen(false);
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      const classList = document.querySelector("body").classList;
      const className = "body-stop-scrolling";

      if (open === true) classList.add(className);
      else classList.remove(className);
    }
  }, [open]);

  return (
    <>
      <div
        className={clsx(
          "p-2.5 min-h-[80px] flex items-center justify-between w-screen transition-all",
          isOnPageTop ? "relative" : "fixed top-0"
        )}
        style={{
          background: colors.Menu.background,
          color: colors.Menu.text,
        }}
      >
        <Link to="/">
          <Image
            data={global.logoMobile}
            alt={global.logoMainAltText}
            height={40}
            loading="eager"
          />
        </Link>

        {/* Hamburger icon */}
        <svg
          viewBox="0 0 100 80"
          width="30"
          height="30"
          onClick={() => setOpen(!open)}
        >
          <rect
            width="100"
            height="20"
            fill={colors.color_hamburger.text}
          ></rect>
          <rect
            y="30"
            width="100"
            height="20"
            fill={colors.color_hamburger.text}
          ></rect>
          <rect
            y="60"
            width="100"
            height="20"
            fill={colors.color_hamburger.text}
          ></rect>
        </svg>

        <div
          id="mobile-menu"
          className={clsx(
            "absolute top-full p-3 bg-white left-1/2 -translate-x-1/2 transition-all drop-shadow-md w-11/12",
            open ? "block" : "hidden"
          )}
          ref={ref}
        >
          {allMenu.nodes.map(
            (
              {
                _id,
                title,
                slug,
                subtitle,
                iconAltText,
                icon,
                menuType,
                articles,
                submenus,
                categoryArticle,
              },
              index
            ) => {
              const menuTitle = (
                <div className="flex flex-col">
                  <p className="text-right">{title}</p>
                  {global.showLvl1Subtitle ? (
                    <p className="m-0 text-sm text-right">{subtitle}</p>
                  ) : null}
                </div>
              );

              const _slug = slug ?? (categoryArticle && categoryArticle.slug);
              const showSubmenu = menuType !== "0";

              return (
                <div
                  key={_id}
                  className="flex flex-col"
                  onClick={() => {
                    if (showSubmenu)
                      setMenuOpen(menuOpen === index ? -1 : index);
                  }}
                >
                  <div
                    className="flex items-center justify-end py-2 mb-1"
                    style={{
                      background: colors.mobile_dropdown.background,
                      color: colors.mobile_dropdown.text,
                    }}
                  >
                    {_slug ? <Link to={_slug}>{menuTitle}</Link> : menuTitle}

                    {global.showLvl1Icon ? (
                      <Image
                        data={icon}
                        alt={iconAltText}
                        width="35"
                        height="35"
                        className={clsx(
                          "h-[35px]",
                          showSubmenu ? "ml-3" : "ml-3 mr-12"
                        )}
                      />
                    ) : null}

                    {showSubmenu ? (
                      <StaticImage
                        src="../../assets/images/expand_more.svg"
                        alt="Dropdown"
                        width={30}
                        height={30}
                        className="mx-2.5"
                      />
                    ) : null}
                  </div>
                  {showSubmenu ? (
                    <div
                      className={clsx(
                        "max-h-80 overflow-y-auto",
                        menuOpen === index
                          ? "h-auto visible pr-4"
                          : "h-0 invisible"
                      )}
                    >
                      {menuType === MENU_TYPE.SHOW_SUBMENU
                        ? (submenus || []).map(({ title, articles }) => {
                            return (
                              <div key={title} className="flex flex-col">
                                <p
                                  className="text-2xl my-3 border-solid border-b-4 text-right"
                                  style={{
                                    borderColor: colors.Action.background,
                                  }}
                                >
                                  {title}
                                </p>
                                {(articles || []).map(({ menuTitle, slug }) => (
                                  <Link
                                    to={slug}
                                    key={menuTitle}
                                    className="block my-1 text-right"
                                    onClick={onNavigate}
                                  >
                                    {menuTitle}
                                  </Link>
                                ))}
                              </div>
                            );
                          })
                        : (articles || []).map(({ menuTitle, slug }) => (
                            <Link
                              to={slug}
                              key={menuTitle}
                              className="block my-1 text-right"
                              onClick={onNavigate}
                            >
                              {menuTitle}
                            </Link>
                          ))}
                    </div>
                  ) : null}
                </div>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
