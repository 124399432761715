import React, { useEffect, useState, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
// context
import { DayjsContext } from "@context/dayjs";
// utils
import mapStaticTexts from "@helpers/map-static-texts";
import traverse from "@helpers/traverse";

/**
 * Component for promotion banner's countdown
 *
 * @component
 */
export const Countdown = () => {
  const { dayjs } = useContext(DayjsContext);
  const data = useStaticQuery(graphql`
    query {
      promotionDisplay {
        endDate
        showCountdown
        textColor
      }
      allStaticText(
        filter: { name: { regex: "/days|hours|minutes|seconds/" } }
      ) {
        nodes {
          text
          name
        }
      }
    }
  `);
  const [onBrowser, setOnBrowser] = useState(false);

  const { promotionDisplay: promotion, allStaticText } = traverse(data, {
    promotionDisplay: null,
    allStaticText: {
      nodes: [
        { text: "", name: "days" },
        { text: "", name: "hours" },
        { text: "", name: "minutes" },
        { text: "", name: "seconds" },
      ],
    },
  });

  // The dayjs object that represent the different between promotion end time and current time
  const [diff, setDiff] = useState(
    dayjs.duration(dayjs(promotion?.endDate).diff(dayjs()))
  );

  // After the diff object has been initialized, creating the countdown
  useEffect(() => {
    if (diff) {
      const timer = setInterval(() => {
        setDiff(diff.subtract(1, "s"));
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [diff]);

  // Getting the static texts
  const staticTexts = mapStaticTexts(allStaticText);

  useEffect(() => {
    setOnBrowser(typeof window !== "undefined");
  }, []);

  return (
    <div className="flex" style={{ color: promotion.textColor }}>
      <div className="flex flex-col items-center mx-2">
        <p>{onBrowser ? diff.days() : null}</p>
        <p>{staticTexts.days}</p>
      </div>
      <div className="flex flex-col items-center mx-2">
        <p>{onBrowser ? diff.hours() : null}</p>
        <p>{staticTexts.hours}</p>
      </div>
      <div className="flex flex-col items-center mx-2">
        <p>{onBrowser ? diff.minutes() : null}</p>
        <p>{staticTexts.minutes}</p>
      </div>
      <div className="flex flex-col items-center mx-2">
        <p>{onBrowser ? diff.seconds() : null}</p>
        <p>{staticTexts.seconds}</p>
      </div>
    </div>
  );
};
