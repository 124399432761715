import React, { useContext, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
// constants
import { SHADOWS } from "@constants/global";
// components
import { Countdown } from "./countdown";
import { CloseButton } from "./close";
// hooks
import traverse from "@helpers/traverse";
// styled
import "./promotion-display.css";
// context
import { EventContext } from "@context/event";
import { AnalyticContext } from "@context/analytic";
import Image from "../image/image";

/**
 * Component for showing promotion banner at the top
 *
 * @component
 */
const PromotionDisplay = ({ promotionDisplay }) => {
  const data = useStaticQuery(graphql`
    query {
      promotionDisplay {
        promotionDisplayText
        buttonText
        buttonLink
        buttonTextColor
        buttonColor
        backgroundColor
        textColor
        showTopBanner
        _id
        bannerLogo {
          output
          file {
            childImageSharp {
              gatsbyImageData(height: 94, width: 94)
            }
          }
        }
        bannerLogoAltText
      }
      global {
        designRadius
        designShadow
      }
    }
  `);
  const { setEvent } = useContext(EventContext);
  const { analyticParameters } = useContext(AnalyticContext);

  const global = useMemo(
    () =>
      traverse(data, {
        global: {
          designRadius: 0,
          designShadow: 0,
        },
      }).global,
    [data]
  );

  const promotion = useMemo(() => {
    if (promotionDisplay) return promotionDisplay;

    return traverse(data, {
      promotionDisplay: null,
    }).promotionDisplay;
  }, [promotionDisplay, data]);

  const show = promotion && promotion.showTopBanner;

  const onButtonClick = () => {
    const dataLayer = window.dataLayer;

    const sendData = () => {
      if (window.dataLayer && promotion)
        window.dataLayer.push({
          ...analyticParameters,
          event: `promotion_banner_click`,
          link_id: "promotion",
          text: promotion.buttonText,
          destination: promotion.buttonLink,
          type: "promotion_banner",
          product: "",
          article: "",
          brand: "",
        });
    };

    if (!dataLayer) {
      setEvent({
        name: "scroll",
      });

      setTimeout(() => {
        sendData();
      }, 3000);
    } else sendData();
  };

  if (show) {
    const {
      backgroundColor,
      promotionDisplayText,
      buttonColor,
      buttonLink,
      buttonText,
      buttonTextColor,
      textColor,
      bannerLogo,
      bannerLogoAltText,
    } = promotion;

    return (
      <div
        id="promotion-display"
        className="w-screen flex flex-col md:flex-row items-center justify-start md:justify-evenly transition-all origin-top"
        style={{
          background: backgroundColor,
          color: textColor,
          "--tw-scale-y": show ? 1 : 0,
          "--promotion-display-p": show ? "1rem" : 0,
          "--promotion-display-height-md": show ? "100px" : 0,
          "--promotion-display-height": show ? "max-content" : 0,
          "--promotion-display-opacity": show ? 1 : 0,
          "--promotion-display-display": show ? "flex" : "hidden",
        }}
      >
        <div className="flex flex-col md:flex-row items-center relative">
          {bannerLogo?.output?.length ? (
            <div className="shrink-0 mr-2 h-full w-[94px]">
              <Image
                className="md:!absolute top-1/2 left-0 md:-translate-y-1/2 !h-[94px]"
                data={bannerLogo}
                alt={bannerLogoAltText}
                width={94}
                height={94}
              />
            </div>
          ) : undefined}

          <div
            className="text-center md:text-left promotion-display-content"
            dangerouslySetInnerHTML={{ __html: promotionDisplayText }}
          />
        </div>

        <Countdown />

        <a
          className={`px-6 py-2 ${SHADOWS[global.designShadow]}`}
          style={{
            background: buttonColor,
            color: buttonTextColor,
            borderRadius: global.designRadius,
          }}
          href={buttonLink}
          target="_blank"
          rel="noopener sponsored noindex"
          onClick={onButtonClick}
        >
          {buttonText}
        </a>

        <CloseButton />
      </div>
    );
  }

  return null;
};

export default PromotionDisplay;
