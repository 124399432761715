import React, { useContext, useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
// context
import { EventContext } from "@context/event";
// utils
import traverse from "@helpers/traverse";
// components
const ConsentBanner = lazy(() => import("../consent-banner/consent-banner"));
const ErrorBoundary = lazy(() => import("../error-boundary/errror-boundary"));
const GtagArticle = lazy(() => import("./gtag-article"));
const GtagLinks = lazy(() => import("./gtag-links"));

const Gtag = () => {
  const data = useStaticQuery(graphql`
    query {
      global {
        showCookieConsent
        googleTagManager
      }
    }
  `);
  const { event } = useContext(EventContext);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [dataLayer, setDataLayer] = useState(undefined);

  const { global } = traverse(data, {
    global: {
      showCookieConsent: false,
      googleTagManager: "",
    },
  });

  useEffect(() => {
    if (event.name === "scroll") {
      setHasScrolled(true);
    }
  }, [event]);

  useEffect(() => {
    if (hasScrolled && !dataLayer) {
      setTimeout(() => {
        setDataLayer(window.dataLayer);
      }, 100);
    }
  }, [hasScrolled]);

  return (
    <>
      {/* Gtag */}
      {hasScrolled ? (
        <Helmet>
          <link rel="preconnect" href="https://www.googletagmanager.com" />
          <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
          <script>{"window.dataLayer = window.dataLayer || []"}</script>
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${global.googleTagManager}');`}</script>
        </Helmet>
      ) : null}
      {hasScrolled && global.showCookieConsent ? (
        <Suspense fallback={<></>}>
          <ErrorBoundary>
            <ConsentBanner />
          </ErrorBoundary>
        </Suspense>
      ) : null}
      {dataLayer ? (
        <Suspense fallback={<></>}>
          <GtagArticle dataLayer={dataLayer} />
          <GtagLinks dataLayer={dataLayer} />
        </Suspense>
      ) : null}
    </>
  );
};

export default Gtag;
