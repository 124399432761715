const traverse = (obj = {}, defaultObj) => {
    const result = { ...obj };
    for (const key in defaultObj) {
      if (
        typeof defaultObj[key] === "object" &&
        !Array.isArray(defaultObj[key])
      ) {
        result[key] = traverse((obj ?? {})[key], defaultObj[key]);
        continue;
      }
  
      if (obj && Array.isArray(obj[key])) {
        result[key] = [...defaultObj[key], ...obj[key]];
  
        continue;
      }
  
      result[key] = obj && obj[key] ? obj[key] : defaultObj[key];
    }
  
    return result;
  };
  
  export default traverse;
  