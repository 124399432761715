import React from "react";
import { graphql, useStaticQuery } from "gatsby";
// hooks
import traverse from "@helpers/traverse";

/**
 * Component for promotion banner close button
 *
 * @component
 */
export const CloseButton = () => {
  const data = useStaticQuery(graphql`
    query {
      promotionDisplay {
        textColor
      }
    }
  `);

  const { promotionDisplay } = traverse(data, {
    promotionDisplay: {
      textColor: "#ffffff",
    },
  });

  const onClose = () => {
    const display = document.getElementById("promotion-display");

    display.style.setProperty("--tw-scale-y", 0);
    display.style.setProperty("--promotion-display-p", 0);
    display.style.setProperty("--promotion-display-height-md", 0);
    display.style.setProperty("--promotion-display-height", 0);
    display.style.setProperty("--promotion-display-opacity", 0);
    display.style.setProperty("--promotion-display-display", "hidden");
  };

  return (
    <button className="absolute top-2.5 right-5" onClick={onClose}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill={promotionDisplay.textColor}
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
      </svg>
    </button>
  );
};
