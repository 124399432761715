import React from "react";
import clsx from "clsx";
// components
import ResponsiveAppBar from "@components/menu/responsive-menu";
import ArticleSchema from "@components/schema/article/article-schema";
import Seo from "@components/seo/seo";
import Gtag from "@components/seo/gtag";
import Footer from "../footer/footer";

/**
 * Component for layouting the page, injecting schemas, metas seo, app bar and promotion banner
 *
 * @component
 */
const LayoutContent = ({ children, fullMode, pageData, location }) => {
  return (
    <div className="overflow-x-hidden">
      <div className="flex flex-col justify-end">
        {/* App Bar */}
        <div className="w-screen z-50">
          <ResponsiveAppBar />
        </div>

        {/* Page content */}
        <div
          className={clsx(
            "flex-grow min-h-screen",
            fullMode ? "" : "max-w-[750px] w-[90vw] mx-auto"
          )}
        >
          {children}
        </div>

        {/* Footer */}
        <Footer />

        <ArticleSchema pageData={pageData} />
        <Seo article={pageData.article} location={location} />

        <Gtag />
      </div>
    </div>
  );
};

LayoutContent.propTypes = {};

export default LayoutContent;
