import { useEffect, useLayoutEffect, useMemo, useState } from "react";

export const useMobileCheck = () => {
  const [rendered, setRendered] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setRendered(true);
    }, 1000)
  }, []);

  return useMemo(() => {
    if (!rendered) return true;

    if (typeof window === "undefined") return true;

    return window.outerWidth <= 720;
  }, [rendered]);
};
