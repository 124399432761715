import React, { lazy, Suspense } from "react";
// components
import MobileMenu from "./mobile-menu";
import { useMobileCheck } from "../../hooks/mobile-check";
const DesktopMenu = lazy(() => import("./desktop-menu"));

const ResponsiveAppBar = () => {
  const isMobile = useMobileCheck()

  return (
    <div className="max-h-[100px] md:min-h-[100px]">
      {isMobile ? (
        <MobileMenu />
      ) : (
        <Suspense fallback={<div style={{ height: 100 }}></div>}>
          <DesktopMenu />
        </Suspense>
      )}
    </div>
  );
};

export default ResponsiveAppBar;
