import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
// utils
import traverse from "@helpers/traverse";

/**
 * Component for injecting SEO tags
 *
 * @component
 */
const Seo = ({article, location}) => {
  const data = useStaticQuery(graphql`
    query {
      global {
        websiteName
        lang
        favicon {
          output
        }
        googleTagManager
        showCookieConsent
      }
    }
  `);

  const { global } = traverse(data, {
    global: {
      websiteName: "",
      lang: "",
      favicon: {
        output: "",
      },
      googleTagManager: "",
      showCookieConsent: false,
    },
  });

  const canonicalUrl = "https://" + global.websiteName + location.pathname;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: global.lang,
        }}
      >
        {/* General tags */}
        <title>{article.content.pageTitle}</title>

        <meta
          name="description"
          content={article.content.metaDescription}
        />
        <link rel="icon" type="image/png" href={global.favicon.output} />
        <link rel="canonical" href={canonicalUrl} />

        {/* For Cloudinary transformation */}
        <meta http-equiv="Accept-CH" content="DPR, Width" />

        {/* Social media */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={article.content.pageTitle} />
        <meta
          name="twitter:description"
          content={article.content.metaDescription}
        />
        <meta
          name="twitter:image"
          content={article.leadPicture.output}
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article.content.pageTitle} />
        <meta
          property="og:description"
          content={article.content.metaDescription}
        />
        <meta property="og:image" content={article.leadPicture.output} />
        <meta property="og:url" content={canonicalUrl} />
        <script defer>
          {`
          window.isTooltipOpen = false;
          window.onTooltip = (id) => {
            Array.from(document.getElementsByClassName('tooltip')).forEach(e => {
              e.classList.add('invisible');
              e.classList.add('opacity-0');
            });
            document.getElementById(id).classList.remove('invisible');
            document.getElementById(id).classList.remove('opacity-0');
            window.isTooltipOpen = true;
          }`}
        </script>
      </Helmet>
      {/* Indexing */}
      {article.type.appearOnGoogle ? (
        <Helmet>
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
          />
        </Helmet>
      ) : (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
    </>
  );
};

Seo.propTypes = {};

export default Seo;
