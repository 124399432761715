import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
// contexts
import { LocationContext } from "@context/location";
import { CurrentArticleContext } from "@context/current-article";
import { SchemaContext } from "@context/schema";
import { AnalyticContext } from "@context/analytic";
// hooks
import traverse from "@helpers/traverse";
// components
import LayoutContent from "./content";
import PromotionDisplayWrapper from "../promotion-display/wrapper";
import EventWrapper from "./event-wrapper";
import DayjsWrapper from "./dayjs-wrapper";
import DynamicFontSize from "../dynamic-font-size/dynamic-font-size";

/**
 * Component for initializing all the contexts and the current article being viewed
 *
 * @component
 */
const Layout = ({ children, location, data, fullMode }) => {
  const globalData = useStaticQuery(graphql`
    query {
      global {
        schemaCurrency
      }
    }
  `);
  const pageData = traverse(data, {
    article: {
      author: {
        image: {
          output: "",
        },
        firstName: "",
        lastName: "",
        link: "/",
        photoAltText: "",
        shortBio: "",
      },
      type: {
        showAuthor: false,
        showReadingTime: false,
        showTableOfContents: false,
        appearOnGoogle: false,
        schemaReview: false,
        schemaArticle: false,
        schemaProductList: false,
        name: "",
        hasHeroImage: false,
      },
      content: {
        pageTitle: "",
        articleTitle: "",
        metaDescription: "",
        content: {
          parsed: "",
          parts: [],
          headers: [],
          images: [],
        },
        readingTime: "",
        createdDate: "",
      },
      faqs: [],
      promotedArticles: [],
      products: [],
      leadPicture: {
        output: "",
      },
      id: "",
      menuTitle: "",
    },
    schema: {
      breadcrumb: "",
      singleProductReview: "",
      productList: "",
      faqs: "",
      articleReview: "",
    },
    allLink: {
      nodes: [],
    },
    promotionDisplay: undefined
  });
  const { global } = traverse(globalData, {
    global: {
      schemaCurrency: "",
    },
  });

  const analyticParameters = useMemo(() => {
    const { type, mainKw, products, content } = pageData.article;

    return {
      article: pageData.article.menuTitle,
      article_page_title: content.pageTitle,
      article_title: content.articleTitle,
      article_type: type?.name ?? "",
      article_main_kw: mainKw,
      article_first_product: products[0]?.name ?? "",
      currency: global.schemaCurrency,
      links: JSON.stringify(pageData.allLink.nodes.map((link) => ({
        ...link,
        product: link.product?.name ?? "",
      }))),
      brand: products[0]?.brand?.name ?? "",
      product: products[0]?.name
    };
  }, [pageData]);

  return (
    <LocationContext.Provider value={location}>
      <CurrentArticleContext.Provider value={pageData.article}>
        <SchemaContext.Provider value={pageData.schema}>
          <AnalyticContext.Provider
            value={{ links: pageData.allLink.nodes, analyticParameters }}
          >
            <EventWrapper article={pageData.article}>
              <DayjsWrapper>
                <PromotionDisplayWrapper promotionDisplay={pageData.promotionDisplay}>
                  <DynamicFontSize>
                    <LayoutContent
                      fullMode={fullMode}
                      pageData={pageData}
                      location={location}
                    >
                      {children}
                    </LayoutContent>
                  </DynamicFontSize>
                </PromotionDisplayWrapper>
              </DayjsWrapper>
            </EventWrapper>
          </AnalyticContext.Provider>
        </SchemaContext.Provider>
      </CurrentArticleContext.Provider>
    </LocationContext.Provider>
  );
};

Layout.propTypes = {
  /**
   * Browser location object
   */
  location: PropTypes.object.isRequired,
};

export default Layout;
