import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// hooks
import traverse from "@helpers/traverse";

/**
 * Component for injecting article schema
 *
 * @component
 */
const ArticleSchema = ({ pageData }) => {
  const data = useStaticQuery(graphql`
    query {
      global {
        organizationSchema
        websiteSchema
      }
    }
  `);
  const { article, schema } = pageData;

  const { global } = traverse(data, {
    global: {
      organizationSchema: "",
      websiteSchema: "",
    },
  });

  return (
    <>
      {article.type.schemaArticle ? (
        <Helmet>
          <script type="application/ld+json">{schema.articleReview}</script>{" "}
        </Helmet>
      ) : null}
      {article.type.schemaReview ? (
        <Helmet>
          <script type="application/ld+json">
            {schema.singleProductReview}
          </script>
        </Helmet>
      ) : null}
      {article.type.schemaProductList ? (
        <Helmet>
          <script type="application/ld+json">{schema.productList}</script>
        </Helmet>
      ) : null}
      <Helmet>
        <script type="application/ld+json">{schema.breadcrumb}</script>
        <script type="application/ld+json">{global.organizationSchema}</script>
        <script type="application/ld+json">{global.websiteSchema}</script>
      </Helmet>
      {article.faqs.length ? (
        <Helmet>
          <script type="application/ld+json">{schema.faqs}</script>
        </Helmet>
      ) : null}
    </>
  );
};

ArticleSchema.propTypes = {};

export default ArticleSchema;
