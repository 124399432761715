export const DEFAULT_TRANSFORMATIONS = ["q_auto:best", "f_auto", "c_fit"];

export const SHADOWS = [
  "shadow",
  "shadow-md",
  "shadow-lg",
  "shadow-xl",
  "shadow-2xl",
];

export const SHADOW_VALUES = [
  "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  "0 25px 50px -12px rgb(0 0 0 / 0.25)",
];

export const TEXT_SIZES = {
  small: "text-sm",
  medium: "text-base",
  large: "text-lg",
};

export const TEXT_VALUES = {
  small: "font-size: 0.875rem;line-height: 1.25rem;",
  medium: "font-size: 1rem;line-height: 1.5rem;",
  large: "font-size: 1.125rem;line-height: 1.75rem;",
};

export const FONT_SIZES = {
  small: "font-size: 0.875rem;",
  medium: "font-size: 1rem;",
  large: "font-size: 1.125rem;",
};

export const FONT_SIZE_VALUES = {
  small: "0.875rem",
  medium: "1rem",
  large: "1.125rem",
};

export const LINE_HEIGHTS = {
  small: "line-height: 1.25rem;",
  medium: "line-height: 1.5rem;",
  large: "line-height: 1.75rem;",
};

export const LINE_HEIGHT_VALUES = {
  small: "1.25rem",
  medium: "1.5rem",
  large: "1.75rem",
};
