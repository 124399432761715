import { useLayoutEffect } from "react"

const DynamicFontSize = ({children}) => {
    useLayoutEffect(() => {
        if (typeof document === undefined)  return;

        if (window.innerWidth < 768) return;
        [...document.querySelectorAll('[data-dynamic-font-size]')].forEach(ele => {
            try {
                const textWrapper = ele.querySelector('[data-dynamic-font-size-text]');
                const text= textWrapper.textContent;
                const availableWidth= ele.clientWidth - 50

                if (textWrapper.clientWidth >= availableWidth) {
                    let fontSize = 13;
                    let newWidth = ele.clientWidth;
                    do {
                        const span = document.createElement('span');
                        span.innerHTML = text;
                        ele.appendChild(span);
                        span.style.fontSize = `${fontSize}px`;
                        newWidth =  span.clientWidth;
                        fontSize -= 1;

                        ele.removeChild(span);
                    } while (newWidth > availableWidth && fontSize > 0)

                    textWrapper.style.fontSize = `${fontSize}px`
                }

                
                
            } catch (error) {
                console.error(error);
            }
        })
    });

    return children
}

export default DynamicFontSize