import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// hooks
import traverse from "@helpers/traverse";

/**
 * Component for footer
 * @component
 */
const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      global {
        footerContent {
          parsed
        }
      }
      color(name: { eq: "Footer" }) {
        background
        text
      }
    }
  `);

  const { global, color } = traverse(data, {
    global: {
      footerContent: {
        parsed: "",
      }
    },
    color: {
      background: "#00000",
      text: "#000000",
    },
  });

  return (
    <div
      className="px-10 py-5"
      style={{
        background: color.background,
        color: color.text,
      }}
      dangerouslySetInnerHTML={{
        __html: global.footerContent.parsed,
      }}
    ></div>
  );
};

Footer.propTypes = {};

export default Footer;
