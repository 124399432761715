import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import duration from "dayjs/plugin/duration";
import "dayjs/locale/es";
import "dayjs/locale/fr";
// contexts
import { DayjsContext } from "@context/dayjs";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

const DayjsWrapper = ({ children }) => {
  const { global } = useStaticQuery(graphql`
    query {
      global {
        lang
      }
    }
  `);

  const locale = (global?.lang ?? "en").slice(0, 2);

  return (
    <DayjsContext.Provider value={{ dayjs, locale }}>
      {children}
    </DayjsContext.Provider>
  );
};

export default DayjsWrapper;
