import React, { useState, lazy, Suspense, useEffect } from "react";
// contexts
import { EventContext } from "@context/event";
// components
const LiteYoutube = lazy(() => import("../lite-youtube/lite-youtube"));

const EventWrapper = ({ children, article }) => {
  const [event, setEvent] = useState({ name: "start", payload: {} });
  const [hasAddedScrollEvent, setHasAddedScrollEvent] = useState(false);
  const [hasAddedClickEvent, setHasAddedClickEvent] = useState(false);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!hasAddedScrollEvent) {
        setHasAddedScrollEvent(true);

        const handleScroll = (event) => {
          setEvent({
            name: "scroll",
            payload: event,
          });
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
      }

      if (!hasAddedClickEvent) {
        setHasAddedClickEvent(true);

        const handleClick = (event) => {
          setEvent({
            name: "click",
            payload: event,
          });

          if (window.isTooltipOpen && !event.target.classList.contains('tooltip')) {
            Array.from(document.getElementsByClassName('tooltip')).forEach(e => {
              e.classList.add('invisible');
              e.classList.add('opacity-0');
            });
            window.isTooltipOpen = false;
          }
        };

        window.addEventListener("click", handleClick, { passive: true, capture: true });
      }
    }
  }, []);

  useEffect(() => {
    setRendered(typeof window !== "undefined");
  }, []);

  useEffect(() => {
    if (rendered)
      setEvent({
        name: "rendered",
        payload: window,
      });
  }, [rendered]);

  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
      {article.content.content.parsed.includes("lite-youtube") && rendered ? (
        <Suspense fallback={<></>}>
          <LiteYoutube />
        </Suspense>
      ) : null}
    </EventContext.Provider>
  );
};

export default EventWrapper;
