import React from "react";

import "./src/assets/styles/global.css";
import "./src/assets/styles/ckeditor.css";

import Layout from "./src/components/layout/layout";

export const wrapPageElement = ({ element, props }) => {
  const { data, location } = props;

  if (data)
    return (
      <Layout
        data={data}
        location={location}
        fullMode={data.article?.type?.name === "Search"}
      >
        {element}
      </Layout>
    );

  return element;
};
